<div class="bc-row bc-margin-top-2">
  <div class="bc-card">
    <div class="bc-card-body">
      <div class="bc-row bc-justify-content-space-between">
        <div class="bc-col-3"></div>
        <div class=" bc-justify-content-center">
          <h1 class="">Homologación Transmisión</h1>
        </div>
        <div class="bc-col-3 bc-flex bc-justify-content-flex-end">
          <a
            bc-link
            class="bc-justify-content-space-between"
            [routerLink]="['/transmision']"
            sizeLink="default"
            ><bc-icon size="lg" name="arrow-left"></bc-icon>
            <h5>Atrás</h5>
          </a>
        </div>
      </div>
      <br />
      <h5 class="bc-text-brand-primary-02">Detalle: {{ fieldName }}</h5>
      <br />
      <div class="bc-row">
        <div class="bc-col-2">
          <button
            (click)="openModalNewField()"
            bc-button
            typeButton="terciary">
            <div class="bc-row">
              <div class="bc-offset-1">
                <bc-icon size="3xl">save</bc-icon>
              </div>
              Grabar
            </div>
          </button>
        </div>
        <div class="bc-col-2">
          <button (click)="clickNew()" bc-button typeButton="terciary">
            <div class="bc-row">
              <div class="bc-offset-1">
                <bc-icon size="3xl">checks</bc-icon>
              </div>
              Nuevo
            </div>
          </button>
        </div>
        <div class="bc-col-2">
          <button
            (click)="openModalCancel(modalCancel)"
            bc-button
            typeButton="terciary">
            <div class="bc-row">
              <div class="bc-offset-1">
                <bc-icon size="3xl" class="bc-icon-left">error</bc-icon>
              </div>
              Cancelar
            </div>
          </button>
        </div>
      </div>
      <br />

      <div class="bc-page-header">
        <div class="bc-container bc-page-header-content bc-col-xs-12">
          <div
            class="bc-page-header-content-left bc-col-2 bc-align-self-center"
          >
            <bc-input-select
              #elementSelect
              componentId="inputSelect"
              formatType="simple"
              [values]="values"
              (valueOutput)="onSelect($event)"
              [defaultIndex]=0
            >
            </bc-input-select>
          </div>
          <div
          class="bc-page-header-content-center bc-col-2 bc-align-self-center"
        >
        <h5>{{ crmType }}</h5>
        </div>
          <div class="bc-col-7 bc-page-header-content-right">
            <div class="bc-col-4">
              <app-total-box [total]="total"></app-total-box>
            </div>
          </div>

          <div class="bc-page-header-content-right">
            <div class="bc-page-header-content-right-icons">
              <input
                (click)="downloadExcel()"
                type="image"
                src="assets/icons/excel_48_color.svg"
                alt="excel"
              />
            </div>
          </div>
        </div>
      </div>
        <app-transmission-detail-table
        ></app-transmission-detail-table>
    </div>
  </div>
</div>
<app-circle-loading></app-circle-loading>

<router-outlet></router-outlet>
<div>
  <bc-modal
    title="Nueva homologación"
    [isCancellable]="true"
    [outerCloseTap]="false"
    #modalNew
    customwidth="large"
    (stateClick)="acceptOrCancel($event)"
    (closeModalEvent)="close()"
    textBtn="Guardar"
    [primaryButtonDisabled]="!newHomologationForm.valid"
    textBtnLeft="Cancelar"
  >
    <div modalContent>
      <div>
        <div class="bc-row">
          <h5 class="bc-col-6 text-center">Detalle: {{ fieldName }}</h5>
          <h5 class="bc-col-6 text-center">Momento: 1 y 4</h5>
        </div>
        <br />

        <form [formGroup]="newHomologationForm" autocomplete="off">
          <div class="bc-row">
            <div class="bc-col-6 bc-col-xs-12">
              <bc-form-field>
                <input
                  bc-input
                  type="text"
                  id="sfcCode"
                  class="bc-input"
                  aria-label="columna1"
                  maxlength="50"
                  formControlName="sfcCode"
                />
                <label for="sfcCode">Código SFC *</label>
                <span *ngIf="isValidField('sfcCode')">{{
                  getFieldError("sfcCode")
                }}</span>
              </bc-form-field>
            </div>
            <div class="bc-col-6 bc-col-xs-12">
              <bc-form-field>
                <input
                  bc-input
                  type="text"
                  id="productName"
                  class="bc-input"
                  aria-label="columna2"
                  maxlength="100"
                  formControlName="sfcName"
                />
                <label for="productName">Nombre SFC *</label>
                <span *ngIf="isValidField('sfcName')">{{
                  getFieldError("sfcName")
                }}</span>
              </bc-form-field>
            </div>
          </div>

          <div class="bc-row">
            <div class="bc-col-6 bc-col-xs-12">
              <bc-form-field>
                <input
                  bc-input
                  type="text"
                  id="idproduct"
                  class="bc-input"
                  aria-label="columna1"
                  maxlength="50"
                  formControlName="crmCode"
                />
                <label for="idproduct">Código CRM *</label>
                <span *ngIf="isValidField('crmCode')">{{
                  getFieldError("crmCode")
                }}</span>
              </bc-form-field>
            </div>
            <div class="bc-col-6 bc-col-xs-12">
              <bc-form-field>
                <input
                  bc-input
                  type="text"
                  id="productName"
                  class="bc-input"
                  aria-label="columna2"
                  maxlength="100"
                  formControlName="crmName"
                />
                <label for="productName">Nombre CRM *</label>
                <span *ngIf="isValidField('crmName')">{{
                  getFieldError("crmName")
                }}</span>
              </bc-form-field>
            </div>
          </div>
          <div class="pt-1 mb-4"></div>
        </form>
      </div>
    </div>
  </bc-modal>
</div>

<div>
  <bc-modal
    title="Nueva homologación"
    [isCancellable]="true"
    [outerCloseTap]="false"
    #modalNew2
    customwidth="large"
    (stateClick)="acceptOrCancel($event)"
    (closeModalEvent)="close()"
    textBtn="Guardar"
    [primaryButtonDisabled]="!newHomologationForm.valid"
    textBtnLeft="Cancelar"
  >
    <div modalContent>
      <div>
        <div class="bc-row">
          <h5 class="bc-col-6 text-center">Detalle: {{ fieldName }}</h5>
          <h5 class="bc-col-6 text-center">Momento: 2 y 3</h5>
        </div>
        <br />

        <form [formGroup]="newHomologationForm" autocomplete="off">
          <div class="bc-row">
            <div class="bc-col-6 bc-col-xs-12">
              <bc-form-field>
                <input
                  bc-input
                  type="text"
                  id="idproduct"
                  class="bc-input"
                  aria-label="columna1"
                  maxlength="50"
                  formControlName="crmCode"
                />
                <label for="idproduct">Código CRM *</label>
                <span *ngIf="isValidField('crmCode')">{{
                  getFieldError("crmCode")
                }}</span>
              </bc-form-field>
            </div>
            <div class="bc-col-6 bc-col-xs-12">
              <bc-form-field>
                <input
                  bc-input
                  type="text"
                  id="productName"
                  class="bc-input"
                  aria-label="columna2"
                  maxlength="100"
                  formControlName="crmName"
                />
                <label for="productName">Nombre CRM *</label>
                <span *ngIf="isValidField('crmName')">{{
                  getFieldError("crmName")
                }}</span>
              </bc-form-field>
            </div>
          </div>

          <div class="bc-row">
            <div class="bc-col-6 bc-col-xs-12">
              <bc-form-field>
                <input
                  bc-input
                  type="text"
                  id="sfcCode"
                  list="sfcCodeOptions"
                  class="bc-input"
                  aria-label="columna1"
                  maxlength="50"
                  formControlName="sfcCode"
                  (keyup)="showSelectedValue()"
                />
                <datalist id="sfcCodeOptions">
                  <option
                    *ngFor="let option of sfcCodeValues"
                    [value]="option"
                  ></option>
                </datalist>
                <label for="sfcCode">Código SFC *</label>
                <span *ngIf="isValidField('sfcCode')">{{
                  getFieldError("sfcCode")
                }}</span>
              </bc-form-field>
            </div>
            <div class="bc-col-6 bc-col-xs-12">
              <bc-form-field [attr.disabled]="sfcNameDisable">
                <input
                  bc-input
                  type="text"
                  id="productName"
                  class="bc-input"
                  aria-label="columna2"
                  maxlength="100"
                  formControlName="sfcName"
                />
                <label for="productName">Nombre SFC *</label>
                <span *ngIf="isValidField('sfcName')">{{
                  getFieldError("sfcName")
                }}</span>
              </bc-form-field>
            </div>
          </div>
          <div class="pt-1 mb-4"></div>
        </form>
      </div>
    </div>
  </bc-modal>
</div>

<div>
  <bc-modal
    customWidth="small"
    typeIcon="warning"
    textBtn="Aceptar"
    textBtnLeft="Cancelar"
    customWidth="large"
    [outerCloseTap]="false"
    (stateClick)="onAcceptDeleteEdit($event)"
    [isCancellable]="true"
    #modalConfirm
          >
            <div modalContent>
              <div>
                <h3 class="text-center">Espera</h3>
              </div>
              <div>
                <h4 class="text-center">
                  {{
                    deleteorEdit
                      ? "Si eliminas estos registros, no podrás recuperarlos nuevamente."
                      : "Estos registros van a ser modificados"
                  }}
                </h4>
              </div>
              <br />
              <div class="bc-container">
                <div class="bc-row">
                  <div
                    class="bc-col-12"
                    componentId="containerTable"
                    *ngIf="dataOperation.length > 0"
                  >
                    <bc-table-content>
                      <div class="table-container-modal">
                        <table bc-table aria-describedby="Lista de Datos" id="table">
                          <thead>
                            <tr>
                              <th
                                *ngFor="
                                  let column of columns.slice(
                                    1,
                                    columns.length - 4
                                  )
                                "
                                bc-cell
                              >
                                {{ column }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let row of dataOperation">
                              <td
                                *ngFor="
                                  let column of columns.slice(
                                    1,
                                    columns.length - 4
                                  )
                                "
                                bc-cell
                              >
                                {{ row[column] }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </bc-table-content>
                  </div>
                </div>
              </div>
              <br />
              <div>
                <h5 class="text-center">
                  {{
                    deleteorEdit
                      ? "¿Estás seguro que deseas eliminar la información seleccionada?"
                      : "¿Estás seguro que deseas grabar la información modificada?"
                  }}
                </h5>
              </div>
            </div>
          </bc-modal>
</div>
<div>
  <bc-modal
    [title]="title"
    textBtn="Confirmar"
    textBtnLeft="Cancelar"
    [isCancellable]="true"
    [outerCloseTap]="false"
    (stateClick)="cancelDeleteorEdit($event)"
    #modalCancel
          >
        <div modalContent>
          <div>
            <h3 class="text-center">Espera</h3>
          </div>
          <div>
            <h4 class="text-center">
              ¿Estás seguro que deseas cancelar dichas operaciones?
            </h4>
          </div>
        </div>
  </bc-modal>
</div>

<div>
  <bc-modal
    [title]="title"
    textBtn="Confirmar"
    textBtnLeft="Cancelar"
    [isCancellable]="true"
    typeIcon="warning"
    [hideCloseButton]="true"
    [outerCloseTap]="false"
    (stateClick)="openModalOperations($event)"
    #modalNewField
          >
            <div modalContent>
              <h3 class="text-center">Espera</h3>
                  <div>
                    <h4 class="text-center">
                      Creaste un nuevo código en la
                      SFC
                    </h4>
                  </div>
                  <div class="bc-container">
                    <div class="bc-row">
                      <div class="bc-col-2"></div>
                      <div class="bc-col-8" componentId="tableId">
                        <bc-table-content>
                          <table
                            bc-table
                            aria-describedby="Lista de Datos"
                            id="table"
                          >
                            <thead>
                              <tr>
                                <th
                                  *ngFor="
                                    let column of newField[0] | keyvalue"
                                  bc-cell
                                >
                                {{column.key}}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                              *ngFor="
                              let element of newField"
                              >
                                    <td
                                    *ngFor="
                                      let data of element | keyvalue
                                    "
                                    bc-cell
                                  >
                                    {{ data.value }}
                                  </td>
                              </tr>
                            </tbody>
                          </table>
                        </bc-table-content>
                      </div>
                    </div>
                    <br />
                  </div>
              <div>
                <h5 class="text-center">
                  ¿Estás seguro que deseas grabar la información creada?
                </h5>
              </div>
            </div>
          </bc-modal>
</div>
<div>
<bc-modal
[title]="title"
textBtn="Confirmar"
textBtnLeft="Cancelar"
typeIcon="warning"
[hideCloseButton]="true"
[isCancellable]="true"
[outerCloseTap]="false"
(stateClick)="confirmNewHomologation($event)"
#modalConfirmNew
>
<div modalContent>
  <div>
    <h3 class="text-center">Espera</h3>
  </div>
  <div>
    <h4 class="text-center">
      {{textNewHomologation}}
    </h4>
  </div>

  <div class="bc-container">
    <div class="bc-row">
      <div class="bc-col-2"></div>
      <div class="bc-col-8" componentId="tableId">
        <bc-table-content>
          <table
            bc-table
            aria-describedby="Lista de Datos"
            id="table"
          >
            <thead>
              <tr>
                <th
                  *ngFor="
                    let column of newHomologation[momentSelected-1]"
                  bc-cell
                >
                {{column}}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                  <td
                    *ngFor="
                    let column of newHomologation[momentSelected-1]"
                    bc-cell
                  >
                    {{ newHomologationData[column] }}
                  </td>
              </tr>
            </tbody>
          </table>
        </bc-table-content>
      </div>
    </div>
    <br />
  </div>
</div>
</bc-modal>
</div>

