import {
  ChangeDetectorRef,
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  Input,
  SimpleChanges
} from '@angular/core';
import { BcModalComponent } from '@bancolombia/design-system-web/bc-modal';
import { CategorizationStoreService } from 'src/app/core/stores/categorizationStore/categorization-store.service';
import { BaseTable } from 'src/app/shared/components/baseTable/BaseTable';
import { CategorizationService } from 'src/app/core/services/categorization/categorization.service';
import { MappingService } from 'src/app/core/services/mapping/mapping.service';
import { colors } from 'src/app/shared/constants/colors';
import Swal from 'sweetalert2';
import { CircleLoadingComponent } from 'src/app/shared/components/circle-loading/circle-loading.component';

@Component({
  selector: 'app-categorization-table',
  templateUrl: './categorization-table.component.html',
  styleUrls: ['./categorization-table.component.scss'],
})
export class CategorizationTableComponent extends BaseTable implements OnInit{
  @ViewChild('modalConfirm', { static: true }) modalConfirm!: BcModalComponent;
  @ViewChild('modal', { static: true }) modal!: BcModalComponent;
  @ViewChild('drop', { static: false }) drop!: ElementRef;
  @ViewChild(CircleLoadingComponent) circleLoading!: CircleLoadingComponent;
  @Input() crmType: string = "";
  public columns: string[] = [];
  public showSecondTable = false;

  public loading = true;
  public columnOptionsFilter: { [key: string]: string[] } = {};
  public filtersApply: Set<string> = new Set();
  public valuesList: { [key: string]: any } = {};
  public editColumns = new Set([
    'Código Producto SFC',
    'Detalle Producto',
    'Código Motivo SFC',
    'Detalle Motivo',
    'Código Canal',
    'Detalle Canal',
    'Código Producto Digital',
    'Producto Digital'
  ]);

  public tableOptions = [
    [
      'Código Producto SFC',
      'product',
      'list-product',
      'productId',
      'Detalle Producto',
    ],
    [
      'Código Motivo SFC',
      'motive',
      'list-motive',
      'motiveId',
      'Detalle Motivo',
    ],
    ['Código Canal', 'channel', 'list-channel', 'channelId', 'Detalle Canal'],
    [
      'Código Producto Digital',
      'digital',
      'list-digital',
      'digitalId',
      'Producto Digital',
    ],
  ];

  constructor(
    private cdr: ChangeDetectorRef,
    private mappingService: MappingService,
    public categorizationStoreService: CategorizationStoreService,
    public categorizationService: CategorizationService
  ) {
    super(categorizationService, categorizationStoreService);
  }

  ngOnChanges(changes:SimpleChanges) {
    if(changes['crmType'].previousValue != undefined){
      if (changes['crmType'].currentValue != changes['crmType'].previousValue){
        this.circleLoading.show(); //Necesario al cambiar de crm 
        this.categorizationStoreService.emptyData()
        this.getHomologationData()
        this.categorizationStoreService.setCurrentPage(1)
      }
    }
  }

  ngOnInit(): void {
    this.categorizationStoreService.getReload().subscribe(() => {
      this.getHomologationData();
    });

    this.categorizationStoreService.getLoading().subscribe((value: boolean) => {
      this.loading = value;
    });

    this.categorizationStoreService.getData().subscribe((data: any) => {
      if (Object.keys(data).length != 0) {
        this.reorganizarData(data);
        this.loading = false;
      }
    });
    this.categorizationStoreService
      .getTotal()
      .subscribe((totalElements: number) => {
        this.total = totalElements;
      });

    this.categorizationStoreService
      .getColumns()
      .subscribe((columns: string[]) => {
        this.columns = columns;
      });

    this.categorizationStoreService
      .getCurrentPage()
      .subscribe((pageNumber: number) => {
        this.currentPage = pageNumber;
      });

    this.categorizationStoreService
      .getValuesAutocomplete()
      .subscribe((ans: any) => {
        this.valuesList = ans;
      });
  }


  /**
   * Permite editar una fila de la tabla de homologaciones.
   */
  editRow(row: any) {
    if (Object.keys(this.valuesList).length == 0) {
      this.categorizationService.getAutocompleteTable();
    }
    const copyRow = JSON.parse(JSON.stringify(row));
    this.categorizationStoreService.setPreviousEditData(copyRow);
    row['Editando'] = true;
    if (row['Color'] == colors['red']) {
      this.categorizationStoreService.cancelDeleteData(row);
    }
    row['Color'] = colors['green'];
    this.categorizationStoreService.setPageforCancel(this.currentPage - 1);
    this.categorizationStoreService.setNewEditData(row);
  }

  /**
   * @ignore
   */
  getHomologationData(page: number = 0, elements: number = parseInt('7'), crmType:string = this.crmType) {
    this.categorizationService.listData(page, elements, crmType).subscribe({
      next: (data: any) => {
        this.categorizationService.removeSymbolFilter();
        data.page = page;
        const resData = this.mappingService.categorizationDataBtoF(data);
        this.categorizationStoreService.setTotal(
          resData.totalElements
        );
        this.categorizationStoreService.setColumns(
          Object.keys(resData.filterObject[0])
        );
        this.categorizationStoreService.setData(resData);
      },
      complete:() =>{
        this.circleLoading.hide();
        this.loading = false
      },
      error:() =>{
        this.circleLoading.hide();
      }
    });
  }

  isNumbers(arrayData: string[]): boolean {
    for (const ele of arrayData) {
      if (isNaN(parseInt(ele))) {
        return false;
      }
    }
    return true;
  }

  /**
   * Habilita el dropdown menu del filtro especifico
   */
  toggleDropdown(event: MouseEvent, dropdown: any, column: string) {
    event.stopPropagation();
    const options = this.columnOptionsFilter[column];
    const haveSearch = this.categorizationStoreService.getHaveSearchFilter();
    if (haveSearch || options == undefined) {
      this.categorizationService.listFilterEspecific(column, this.crmType).subscribe({
        next: (data: any) => {
          if (this.isNumbers(data)) {
            data = data.sort((a: number, b: number) => a - b);
          } else {
            data = data.sort();
          }
          this.columnOptionsFilter[column] = data;
        },
      });
    }
    if (dropdown) {
      if (this.activeDropdown != null) {
        this.activeDropdown.classList.remove('active');
      }

      if (this.activeDropdown === dropdown) {
        this.activeDropdown = null;
      } else {
        if (this.disableAll) {
          this.activeDropdown = null;
        } else {
          this.activeDropdown = dropdown;
          this.activeDropdown.classList.toggle('active');
        }
      }
    }
  }

  /**
   * @ignore
   */
  reorganizarData(data: any) {
    
      this.arrayData = data[this.currentPage - 1];
      this.currentPageItems = this.arrayData;
      this.cdr.detectChanges();
      setTimeout(() => {   
      this.circleLoading.hide();
    }, parseInt("200"));
  }

  /**
   * Determina las acciones a realizar cuando se cambia de página en el paginado.
   */
  onChangePage(paginationData: any) {
    this.circleLoading.show(); 
    this.itemsPerPage = paginationData.PerPage;
    this.currentPage = paginationData.CurrentPage;
    const changePage = this.currentPage - 1;
    const valuesPage = this.categorizationStoreService.getData().getValue();
    const exists = Object.keys(valuesPage).includes(changePage.toString());
    if (exists) {
      this.reorganizarData(valuesPage);
    } else {
      const isSearch = this.categorizationStoreService.getIsSearch();
      const isFilter = this.categorizationStoreService.getIsFilter();
      if (isSearch || isFilter) {
        const qr: any = this.categorizationStoreService
          .getRequestData()
          .getValue();
        qr.page = changePage;
        this.categorizationService.listFilter(qr,this.crmType).subscribe({
          next: (data: any) => {
            data['page'] = qr.page;
            const resData = this.mappingService.categorizationDataBtoF(data);
            this.categorizationStoreService.setData(resData);
            this.categorizationStoreService.setRequestData(qr);
          },
          complete:() => {
            this.circleLoading.hide();
          },
          error: () => {
            this.circleLoading.hide();
          }
        });
      } else {
        this.getHomologationData(changePage);
      }
    }
  }

  /**
   * Actualiza el valor de la columna hermana en la tabla (Ej: Código Producto SFC y Detalle Producto).
   */
  updateValue(inputGeneric: any, row: any, column: string, enable: boolean) {
    const actualValue = (inputGeneric.target as HTMLInputElement).value;
    const mappingColumns = this.mappingService.categorizationUpdateFtoF(column);
    const inputDetail = <HTMLInputElement>(
      document.getElementById(mappingColumns[parseInt('3')] + row['ID'])
    );
    if (actualValue in this.valuesList[column]) {
      inputDetail.readOnly = true;
      row[mappingColumns[0]] = actualValue;
      row[mappingColumns[1]] = this.valuesList[column][actualValue][1];
      row[mappingColumns[parseInt('2')]] =
        this.valuesList[column][actualValue][0];
      this.categorizationStoreService.setNewEditData(row);
    } else {
      if (enable) {
        row[mappingColumns[0]] = actualValue;
        row[mappingColumns[1]] = '';
        row[mappingColumns[parseInt('2')]] = null;
        inputDetail.readOnly = false;
      } else {
        row[mappingColumns[1]] = actualValue;
        this.categorizationStoreService.setNewEditData(row);
      }
    }
  }

  clearFilter($event: any, column: string, option: number) {
    this.searchFilter($event, column, option);
  }

  /**
   * Permite hacer la busqueda por el valor en una columna especifica.
   */
  searchFilter($event: any, column: string, option: number) {
    this.circleLoading.show();
    const columnSearch = this.mappingService.categorizationColumnFtoB(column);
    let qr: any = this.categorizationStoreService.getRequestData().getValue();
    qr = this.updateQr(qr, option, columnSearch, $event);
    if (
      Object.keys(this.categorizationStoreService.getDeleteData()).length !=
        0 ||
      Object.keys(this.categorizationStoreService.getPreviousEditData())
        .length != 0
    ) {
      Swal.fire({
        title:
          "<p style='color:black'>Hay cambios sin guardar,esta seguro que desea continuar</p>",
        position: 'top',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        allowOutsideClick: false,
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'customButton customButton-primary',
          cancelButton: 'customButton customButton-secondary',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.filterConfiguration(column, option, qr);
        }
        else{
          this.circleLoading.hide();
        }
      });
    } else {
      this.filterConfiguration(column, option, qr);
    }
  }

  filterConfiguration(column: string, option: number, qr: any) {
    this.categorizationStoreService.emptyPreviousEditData();
    this.categorizationStoreService.emptyNewEditData();
    this.categorizationStoreService.emptyDeleteData();
    this.filtersApply.delete(column);
    if (option == 0 || this.filtersApply.size != 0) {
      this.categorizationStoreService.setIsFilter(true);
    } else {
      this.categorizationStoreService.setIsFilter(false);
    }
    this.applyFilter(qr, option, column);
    if (option == 0) {
      this.filtersApply.add(column);
    }
  }

  /**
   * @ignore
   */
  applyFilter(qr: any, option: number, column: string) {
    const isFilter = this.categorizationStoreService.getIsFilter();
    const isSearch = this.categorizationStoreService.getIsSearch();
    if (isSearch || isFilter) {
      this.categorizationService.listFilter(qr, this.crmType).subscribe({
        next: (data: any) => {
          this.postResponseFilter(data, qr, option, column, 0);
        },
        complete:() =>{
          this.circleLoading.hide();
        },
        error: () =>{
          this.circleLoading.hide();
        }
      });
    } else {
      this.categorizationService.listData(0, parseInt('7'), "SAP").subscribe({
        next: (data: any) => {
          this.postResponseFilter(data, qr, option, column, 1);
        },
        complete:() =>{
          this.circleLoading.hide();
        },
        error: () =>{
          this.circleLoading.hide();
        }
      });
    }
  }

  // type 0 is qr search 1 is normal search
  /**
   * @ignore
   */
  postResponseFilter(
    data: any,
    qr: any,
    option: number,
    column: string,
    type: number
  ) {
    data['page'] = 0;
    const resData = this.mappingService.categorizationDataBtoF(data);
    this.categorizationStoreService.setTotal(resData.totalElements);
    this.categorizationStoreService.setCurrentPage(1);
    this.categorizationStoreService.setAllData(resData);
    if (type == 0) {
      this.categorizationStoreService.setRequestData(qr);
    }
    const componentem = document.getElementById(column);
    if (componentem) {
      componentem.innerHTML = option == 0 ? 'filter' : 'arrow2-down';
    }
  }

  /**
   * @ignore
   */
  updateQr(
    qr: any,
    option: number,
    columnSearch: string[],
    selectedValue: string

  ) {
    if (option == 0) {
      if (columnSearch.length == 1) {
        qr.filterObject[columnSearch[0]] = selectedValue;
      } else {
        qr.filterObject[columnSearch[0]][columnSearch[1]] = selectedValue;
      }
    } else {
      if (columnSearch.length == 1) {
        qr.filterObject[columnSearch[0]] = '';
      } else {
        qr.filterObject[columnSearch[0]][columnSearch[1]] = '';
      }
    }
    this.activeDropdown.classList.remove('active');
    qr.page = 0;
    return qr;
  }
}
