import { IMenuPages } from "src/app/core/models/categorization/IMenuPages";

export const Menu:IMenuPages[]=[
    {
      text: 'Inicio',
      icon: 'home',
      path: 'inicio'
    },
    {
      text: 'Monitoreo y Control',
      icon: 'analytics',
      path: 'categorizacion/reporte'
    },
    {
      text: 'Homologación Categorización',
      icon: 'hand-button',
      path: 'categorizacion/homologacion'
    },
    {
      text: 'Homologación Transmisión',
      icon: 'cash-contribution',
      path: 'transmision'
    },
    {
      text: 'Historial de Modificación',
      icon: 'refresh',
      path: 'history'
    },
    {
      text: 'Páginas SFC',
      icon: 'data',
      path: 'categorizacion/sfc'
    }

  ];
