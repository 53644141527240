<div class="bc-container bc-margin-bottom-4">
  <div class="bc-row bc-margin-top-4">
    <div class="bc-col-12">
      <h2 id="titulo pagina SFC">Páginas SFC</h2>
    </div>
  </div>
  <br />
  <div class="bc-container">
    En esta sección encuentras links de interés general, relacionados con
    SmartSupervision.<br /><br />
  </div>
  <div class="bc-container">
    <a target="_blank" rel="noopener" [href]="linkHomologaciones"
      id="link tablas anexas">Tablas anexas para el reporte de información (homologación):</a
    >
    Acá encontramos las tablas para la homologación del reporte. <br />
    De esta sección aplican las siguientes tablas:
    <ul>
      <li>Tabla anexo quejas</li>
      <li>Tabla canal</li>
      <li>Tabla condición especial</li>
      <li>Tabla ente de control</li>
      <li>Tabla sexo</li>
      <li>Tabla tipo de identificación</li>
      <li>Tabla tipo de persona</li>
    </ul>
  </div>
  <br />
  <div class="bc-container">
    <a target="_blank" rel="noopener" [href]="linkWebService"
      id="link documento tecnico">Documento Técnico:</a
    >
    Acá encontramos el web service para la construcción y desarrollo de
    SmartSupervision.
  </div>
  <br />
  <div class="bc-container">
    <a target="_blank" rel="noopener" [href]="linkPortalSfc"
      id="link plataforma administrativa sfc">Plataforma Administrativa SmartSupervision SFC:</a
    >
    Página web desarrollada por la Superintendencia Financiera de Colombia donde
    se puede consultar los AQR´s recibidos y enviados por SmartSupervision, para
    ingresar debe tener usuario y contraseña de administrador.
  </div>
</div>